// Get the toggle button element by class name
const closeButton = document.querySelector('.js-close-facets-btn');
const openButton = document.querySelector('.js-open-facets-btn');

const facetsContainer = document.querySelector('.js-facet-container');

// Reopen filter on page reload.
if(sessionStorage.getItem('filter-opened')){
    facetsContainer.classList.add('js-facet-container--show');
    openButton.classList.add('js-open-facets-btn--show');
}

openButton.addEventListener('click', () => {
    facetsContainer.classList.add('js-facet-container--show');
    sessionStorage.setItem('filter-opened', String(true));
    openButton.classList.add('js-open-facets-btn--show');
    window.scrollTo({ top: 0, behavior: 'smooth' });
});

closeButton.addEventListener('click', () => {
    facetsContainer.classList.remove('js-facet-container--show');
    openButton.classList.remove('js-open-facets-btn--show');
    sessionStorage.removeItem('filter-opened');
});


const showMoreFacetsElements = document.querySelectorAll('.js-show-more-facets');
showMoreFacetsElements.forEach(showMoreFacetsElement => {
    showMoreFacetsElement.addEventListener('click', () => {
        import("./facets/showMoreFacets.js" + "?v=" + Math.floor(Date.now() / 100000))
            .then((module) => {
                module.showMoreFacets(showMoreFacetsElement);
            })
            .catch((err) => {
                console.error('ERROR:', err);
            });
    });
});


const searchFacetsButtons = document.querySelectorAll('.js-search-facets-button');
searchFacetsButtons.forEach(searchFacetsButton => {
    searchFacetsButton.addEventListener('click', () => {
        import("./facets/searchFacets.js" + "?v=" + Math.floor(Date.now() / 100000))
            .then((module) => {
                module.searchFacets(searchFacetsButton);
            })
            .catch((err) => {
                console.error('ERROR:', err);
            });
    });
});
